.textAlignCenter {
  text-align: center;
}

.heightFull {
  height: 100%;
}

.widthFull {
  width: 100%;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.px-10 {
  padding: 0px 10px;
}

.px-20 {
  padding: 0px 20px;
}
.p-15 {
  padding: 15px;
}

.layoutContentContainer {
  background: #f5f7fe !important;
}
.sidebarContainer {
  padding-top: 20px;
  @extend .heightFull;
  .ant-menu {
    @extend .heightFull;
  }
}

.appIcon {
  height: 50px;
}
