.inputWizardStep {
  padding: 10px;
  cursor: pointer;
  .ant-typography {
    font-size: 12px;
  }
}
.selectedInputWizardStep {
  @extend .inputWizardStep;
  border-left: 2px solid #005295;
  box-shadow: 0px 1px 8px #00000029;
  border-radius: 4px;
}
