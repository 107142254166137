.ant-layout-header {
  box-shadow: 0 2px 8px #dce0e5;
  padding: 0px 15px 0px 15px !important;
  background-color: white !important;
  .cloudLogo {
    img {
      width: 130px;
    }
  }
  .ant-menu-horizontal {
    border: none !important;
  }
}
