.appInputCol {
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.appInstructionCol {
  height: 100%;
  background: white;
  padding: 20px;
  overflow: auto;
}

.inputInstruction {
  padding: 15px;
}

.inputInstructionWithError {
  @extend .inputInstruction;
  background: #ffb1b13b;
}
