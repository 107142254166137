.searchBox {
  width: 50% !important;
}

.appListSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  overflow: auto;
  .appListContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .appItem {
      width: 250px;
      cursor: pointer;
      .ant-card-body {
        height: 120px;
      }
    }
  }
}

.appDomain {
  border-bottom: 1px solid #dedfe5;
  padding: 5px 0px;
}

.ifCategorySelected {
  background: #e6f7ff;
}
